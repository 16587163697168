














import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  components: {
    MDamageForm: () => import("@/components/molecules/m-damage-form.vue"),
  },
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
  emits: ["update"],
  setup() {
    const isOpen = ref(false);

    const open = () => (isOpen.value = true);
    const close = () => (isOpen.value = false);
    const toggle = () => (isOpen.value ? close() : open());

    return { isOpen, open, close, toggle };
  },
});
